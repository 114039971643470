1<template>
    <div class="my-form margin-t-105">
        <a-form :form="form">
            <template>
                <a-form-item label="流程审批模板" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select placeholder="请选择" v-model="selectTemplateId" @change="templateChange">
                        <a-select-option value="">无</a-select-option>
                        <a-select-option v-for="(item, index) in templateMessList" :key="index" :value="item.id">{{
                            item.name
                        }}</a-select-option>
                    </a-select>
                    <template #extra>
                        <span class="moreTemp" @click="$router.push({ path: '/fileStreamTemplate' })"
                            >点击添加更多模板>></span
                        >
                    </template>
                </a-form-item>
                <div class="tip">
                    <div>注：</div>
                    <div>
                        <div>可以点击添加更多模板设置模板以供下次直接选用</div>
                        <div>如不使用模板可以点击下方的“添加节点”自定义审批节点</div>
                    </div>
                </div>
                <div class="line"></div>
                <template v-if="selectTemplate">
                    <templateNodes :nodes="selectTemplate.nodeList"></templateNodes>
                </template>
                <div v-else class="font">
                    <nodes ref="nodes" @change="setNodesMess" />
                </div>
            </template>

            <div class="submit-bt">
                <template v-if="todoType === 'registration'">
                    <a-button type="primary" @click="handleSubmit('saveAndApproval')" size="large">
                        保存并发起审批
                    </a-button>
                    <a-button type="primary" @click="handleSubmit('save')" size="large"> 保存登记 </a-button>
                </template>
                <a-popconfirm v-else placement="topRight" title="发起后也可以增加节点" @confirm="() => addApproval()">
                    <a-button type="primary" size="large" ghos> 发起审批 </a-button>
                </a-popconfirm>
                <!-- <a-button v-else type="primary" @click.stop="addApproval" size="large" ghos> 发起审批 </a-button> -->
            </div>
        </a-form>
        <selectPersonDialog ref="selectPersonDialog" @selectTodoMsg="selectTodoMsg" />
    </div>
</template>

<script>
import {
    createApproval,
    documentBillAdd,
    documentBillDetail,
    documentBillEditRegistration,
} from '@/api/modular/fileStream/documentManage'
import { templateList } from '@/api/modular/fileStream/template'
import { selectPerson } from '@/mixins'
import { EventBus } from '@/views/fileStream/documentManage/bus.js'
import { customFromValidate } from '@/views/fileStream/documentManage/minxins.js'
import templateNodes from '@/views/fileStream/documentManage/templateNodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import nodes from '@/views/fileStream/documentSend/components/nodes.vue'
import moment from 'moment'

export default {
    components: {
        OpenData,
        templateNodes,
        selectPersonDialog,
        nodes,
    },
    mixins: [selectPerson, customFromValidate],
    computed: {},
    data() {
        return {
            moment,
            subLoad: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            form: this.$form.createForm(this),
            list: [],
            templateMessList: [],
            selectTemplateId: '',
            selectTemplate: null,
            csr_list: [],
            main_list: [],
            form_id: null,
            form_state: {},
            documentNodeList: [], // 节点列表
            // 表单对象
            tableForm: null,
        }
    },
    props: {
        content: {
            type: String,
            default: '',
        },
        fileIds: {
            type: Array,
            default() {
                return []
            },
        },
        todoType: {
            type: String,
            default: 'registration',
        },
        officialId: {
            type: String,
            default: '',
        },
        randomCode: 0,
    },
    watch: {
        list: {
            handler(newVal) {
                EventBus.$emit('addDom', newVal)
            },
            immediate: true,
        },
        randomCode: {
            immediate: true,
            handler() {
                this.get_info()
            },
        },
        selectTemplateId(val) {
            this.templateChange(val)
        },
    },
    created() {
        this.getTemplateList()
        this.getFormStateData()
    },
    destroyed() {
        EventBus.$off('form_state', {})
    },
    methods: {
        getFormStateData() {
            EventBus.$on('form_state', (obj, form) => {
                this.form_state = obj
                this.tableForm = form
            })
        },
        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        // 节点配置
        setNodesMess(evt) {
            const { list, csrList, mainList } = evt
            this.csr_list = csrList
            this.main_list = mainList
            this.list = list
        },
        // 处理节点人员

        initNodes(tag) {
            const { nodeList } = tag
            const formatNodes = (nodes) => {
                return nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
            }
            this.list = nodeList
                .filter((j) => ![99, 100].includes(j.sort))
                .map((i) => ({ name: i.name, val: formatNodes(i.userList), countersign: i.countersign }))
            this.csr_list = nodeList
                .find((j) => j.sort === 100)
                .userList.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
            this.main_list = nodeList
                .find((j) => j.sort === 99)
                .userList.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
        },
        // 节点配置
        templateChange(evt) {
            this.selectTemplateId = evt
            const tem = this.templateMessList.find((j) => j.id === evt)
            if (tem) {
                this.selectTemplate = tem
                this.initNodes(tem)
            } else {
                this.selectTemplate = null
                if (this.documentNodeList && this.documentNodeList.length) {
                    this.echoNodes(this.documentNodeList)
                } else {
                    this.list = [{ name: '领导审批', val: [] }]
                    this.csr_list = []
                    this.main_list = []
                }
            }
        },
        formatDraftId(draftId, key) {
            if (!draftId) return
            return JSON.parse(draftId)[0][key]
        },
        getTemplateList() {
            templateList({ status: 1 }).then((res) => {
                if (res.code === 200) {
                    this.templateMessList = res.data
                }
            })
        },
        resetMess() {
            this.main_list = []
            this.csr_list = []
            this.list = [{ name: '领导审批', val: [] }]
            this.form_state = {}
            this.form.resetFields()
            this.selectTemplateId = ''
            this.selectTemplate = null
            this.$refs.nodes && this.$refs.nodes.reset()
        },
        resetList() {
            this.resetMess()
        },
        get_info() {
            this.form_id = this.officialId
            const id = this.form_id
            if (id) {
                documentBillDetail({ id }).then((res) => {
                    const {
                        acceptTime,
                        name,
                        urgencyDegree,
                        secrecyDegree,
                        deviseOpinion,
                        draftId,
                        nodeParam,
                        tellPhone,
                        summary,
                        title,
                        documentNodeList,
                        documentFileList,
                        documentNumber,
                        receiverCompany,
                        mainFile,
                        templateId,
                    } = res.data
                    const form = {
                        lwsj: acceptTime,
                        name,
                        jjcd: urgencyDegree ? String(urgencyDegree) : '',
                        miji: secrecyDegree ? String(secrecyDegree) : '',
                        nbyj: deviseOpinion,
                        receiverCompany,
                        draftId: this.formatDraftId(draftId),
                        // spfs: '1' || String(approvalWay),
                        wjbt: title,
                        list: nodeParam,
                        nodes: documentNodeList,
                        tellPhone,
                        attach: mainFile && [mainFile], //正文
                        fileIds: Array.isArray(documentFileList) ? documentFileList : [], //附件
                        summary,
                        documentNumber,
                        templateId,
                    }
                    //
                    console.log('form+++++++++', form)
                    this.documentNodeList = documentNodeList
                    this.echoNodes(documentNodeList)
                    this.selectTemplateId = templateId == '-1' ? '' : templateId
                    this.form.setFieldsValue(form)
                    this.tableForm.setFieldsValue(form)
                    this.$emit('setDetail', form)
                })
            } else {
                this.resetMess()
            }
        },
        init_approvals(arr) {
            const data = []
            arr.forEach((element) => {
                var obj = {}
                element.nodes && element.nodes.length > 0 ? (obj.val = element.nodes) : (obj.val = [])
                obj.name = element.name
                data.push(obj)
            })
            return data
        },

        async addApproval() {
            let arr = await this.handlerNodeList()
            if (!arr) return
            createApproval({
                billId: this.officialId,
                processesList: arr,
                approvalWay: 1,
                templateId: this.selectTemplateId,
            })
                .then((res) => {
                    if (res.code === 200) {
                        this.$emit('close')
                        this.$message.success('审批发起成功')
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async handleSubmit(todo) {
            let arr = await this.handlerNodeList()
            if (!arr) return
            if (this.subLoad) return
            // let arr = []
            let func = documentBillAdd
            const id = this.form_id
            if (id) {
                func = documentBillEditRegistration
            }
            this.tableForm.validateFields((err, values) => {
                this.setFromValidateState({ fromValidateState: true })
                if (!err) {
                    let params = {
                        draftId: this.form_state.draftId,
                        name: this.form_state.name,
                        fileIds: this.form_state.fileIds,
                        attach: this.form_state.attach,
                    }
                    this.form_state = { ...values, ...params }
                    if (!this.form_state.name) return
                    if (params.draftId?.length < 1) return
                    this.subLoad = true
                    const obj = {
                        acceptTime: this.form_state?.lwsj ? moment(this.form_state?.lwsj).format('YYYY-MM-DD') : null,
                        name: this.form_state?.name,
                        secrecyDegree: Number(this.form_state?.miji),
                        urgencyDegree: Number(this.form_state?.jjcd),
                        deviseOpinion: this.form_state?.nbyj,
                        receiverCompany: this.form_state?.receiverCompany,
                        draftId: this.filter(this.form_state?.draftId),
                        nodeParam: {
                            templateId: this.selectTemplateId,
                            processesList: arr,
                        },
                        tellPhone: this.form_state?.tellPhone,
                        summary: this.form_state?.summary,
                        documentType: 1,
                        documentNumber: this.form_state?.documentNumber,
                        title: this.form_state?.wjbt,
                        attach: this.form_state?.attach && this.form_state?.attach[0],
                        fileIds: this.form_state?.fileIds,
                        status: todo === 'save' ? 1 : 2,
                        templateId: values?.templateId,
                    }

                    if (id) {
                        obj.id = id
                    }
                    // 验证必填项
                    // let isValidate = this.validateInfo(obj)
                    func(obj)
                        .then((res) => {
                            if (res.code === 200) {
                                this.$message.success('登记成功')
                                if (todo === 'save') {
                                } else if (todo === 'saveAndApproval') {
                                    this.$emit('changeStatus', {
                                        officialId: this.officialId || res.data,
                                        todoType: 'approval',
                                    })
                                }
                                this.$emit('close')
                                this.resetMess()
                            }
                        })
                        .finally(() => {
                            this.subLoad = false
                        })
                } else {
                    this.subLoad = false
                    this.$message.error('表单填写未完成，请继续填写')
                }
            })
        },
        addDom() {
            this.list.push({ name: '节点', val: [] })
        },
        editDom() {
            const dom = arguments[0].target.parentElement.getElementsByClassName('input-dom')[0]
            dom.removeAttribute('readOnly')
            dom.classList.remove('input-dom-edit')
        },
        delDom(i) {
            this.list.splice(i, 1)
        },
        choosePerson(list, index) {
            let val
            if (list === 'list') {
                val = [...this[list][index].val]
            } else {
                val = [...this[list]]
            }
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
                isList,
            } = getUserListMsg(list, index, val)
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
                type: this.type, // 1 部门 其他 员工
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    let isUserNode =
                        this.confineNodeSelectByChoosePerson && this.confineNodeSelectByChoosePerson(result, this[list])
                    if (isList) {
                        isUserNode && this.$set(this[list][index], 'val', [...result, ...resPart])
                    } else {
                        isUserNode && this.$set(this, list, [...result, ...resPart])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        // 处理通讯录数据
        filter(ids) {
            return (
                ids &&
                JSON.stringify(
                    ids.map(({ corpId, userId, id, type }) => ({
                        [this.getIdKey(type)]: userId || id,
                        corpId,
                        type,
                    }))
                )
            )
        },
        formatDraftId(id) {
            if (!id) return []
            return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
        },
        // 回显审批节点
        echoNodes(nodeList = []) {
            if (Array.isArray(nodeList)) {
                const formatNodes = (nodes) => {
                    return nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
                }
                this.list = nodeList
                    .filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
                    .map((i) => ({ name: i.name, val: formatNodes(i.nodes), countersign: i.countersign }))
                this.csr_list = nodeList
                    .find((j) => j.sort === 100)
                    .nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
                this.main_list = nodeList
                    .find((j) => j.sort === 99)
                    .nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
                this.$refs.nodes.initData({ list: this.list, csrList: this.csr_list, mainList: this.main_list })
            }
        },
        // 处理节点数据
        handlerNodeList() {
            const arr = []
            if (this.list[0].val.length === 0) {
                this.$message.error('审批流程未选择，请选择审批人员')
                return
            } else if (this.list.some((j) => !j.val || j.val.length === 0)) {
                this.$message.error('审批流程未选择，请选择审批人员')
                return
            } else {
                this.list.forEach((el, i) => {
                    const uid = []
                    const departId = []
                    el.val.forEach((el) => {
                        if (el.type === 'departmentName') {
                            departId.push({ departmentId: el.id, corpId: el.corpId })
                        } else if (el.type === 'userName') {
                            uid.push({ userId: el.userId, corpId: el.corpId, status: 0 })
                        }
                    })
                    arr.push({ name: el.name, sort: i, userList: uid, countersign: el.countersign })
                })
                // eslint-disable-next-line camelcase
                arr.push({
                    name: '接收人',
                    sort: 99,
                    userList: this.main_list.map((j) => ({
                        userId: j.userId,
                        corpId: j.corpId,
                        status: 0,
                    })),
                })
                arr.push({
                    name: '抄送人',
                    sort: 100,
                    userList: this.csr_list.map((j) => ({
                        userId: j.userId,
                        corpId: j.corpId,
                        status: 0,
                    })),
                })
            }
            return arr
        },
    },
}
</script>

<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.flex0 {
    display: flex;
    align-items: center;
}
.font {
    font-size: 16px;
}

.my-form {
    // width: 478px;
    margin-left: 10px;
    background: #fff;
    padding: 20px 0;
    ::v-deep .ant-calendar-picker {
        width: 100%;
    }
}
::v-deep .ant-form-item-label {
    text-align: left;
}

.line {
    height: 1px;
    border: 1px dashed #3b9afa;
    display: block;
    width: 100%;
    margin: 30px 0;
}
.plus-bt {
    width: 46px;
    height: 46px;
    background: #e9f2fe;
    border-radius: 4px;
    margin: 10px 0;
}
.left-line::before {
    content: '';
    height: 80px;
    position: relative;
    top: 0px;
    left: 13px;
    width: 2px;
    background: #cdcfd1;
}
.left-line-last:before {
    background: white;
}
.sp-icon {
    width: 26px;
    height: 26px;
    background: #cdcfd1;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: white;
}
.submit-bt {
    text-align: right;
    margin: 40px 0 40px;
    button {
        // width: 100px;
        height: 36px;
        background: #3b9afa;
        border-radius: 4px;
        margin-right: 10px;
    }
}
.edit-icon {
    margin-left: 10px;
}
.zusi {
    font-size: 12px;
    color: #acacac;
}
.input-dom {
    width: 100px;
    margin-left: 6px;
}
.input-dom-edit {
    border: none;
}
.input-dom-edit:hover {
    border: none;
}
.input-dom-edit:focus {
    border: none;
}
.tags {
    height: 46px;
    background: #f2f2f3;
    border: none;
    margin-bottom: 10px;
    button {
        margin: 0;
    }
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.tag-box {
    margin: 10px 0 10px 30px;
}
.arrow-right {
    font-size: 0.6em;
    color: #aaa;
    margin-right: 10px;
}
.tip {
    display: flex;
    font-size: 12px;
    color: #b9b9b9;
}
::v-deep .ant-form-extra {
    margin-top: 5px;
}
.moreTemp {
    cursor: pointer;
    color: #3b9afa;
}
</style>
